import React from 'react'
import Tabs from 'components/Tabs'
import PageTitle from 'components/PageTitle'
import ContentBox from 'components/ContentBox'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import RelatoriosDetalhados from './RelatoriosDetalhados'
import RelatoriosResumidos from './RelatoriosResumidos'
// import ExportarDados from './ExportarDados'
import Auditoria from './Auditoria'

const useStyles = makeStyles((theme) => ({
  contentBox: {
    padding: theme.spacing(1, 2),
  },
  panel: {
    padding: theme.spacing(4, 2),
  },
}))

const Relatorio = () => {
  const { t } = useTranslation()
  const classes = useStyles()

  const tabs = [
    { key: '0', title: t('relatorios.detalhados'), component: RelatoriosDetalhados },
    { key: '1', title: t('relatorios.resumidos'), component: RelatoriosResumidos },
    { key: '2', title: t('relatorios.auditoria'), component: Auditoria },
    // { key: '3', title: t('relatorios.exportarDados'), component: ExportarDados },
  ]

  return (
    <>
      <PageTitle title={t('menuItems.relatorios')} />
      <ContentBox className={classes.contentBox}>
        <Tabs
          renderTabs={({ Tab }) => tabs.map((tab) => (
            <Tab label={tab.title} value={tab.key} key={tab.key} />
          ))}
        >
          {({ TabPanel }) => tabs.map(({ component: Component, ...tab }) => (
            <TabPanel className={classes.panel} value={tab.key} key={tab.key}>
              <Component />
            </TabPanel>
          ))}
        </Tabs>
      </ContentBox>
    </>
  )
}

export default Relatorio
